import React from "react";
import milma from "../../../assets/images/partners/milma1.png";
import amul from "../../../assets/images/partners/amul1.png";
import kmf from "../../../assets/images/partners/kmf.jpg";
import karnataka from "../../../assets/images/partners/karnataka.jpg";
import styled from "styled-components";
import { Fade } from "react-reveal";

const Partners = () => {
  return (
    <>
      <Fade bottom duration={2000}>
        <MainContainer>
          <Title>
            We Work With Our Partners To Provide Perfection,
            <span>Join With Our Parnership.</span>
          </Title>
          <div class="image-slider">
            <div class="image-slider-track">
              <div class="slide">
                <img src={milma} className="img-sl" alt="image" />
              </div>
              <div class="slide">
                <img src={amul} className="img-sl" alt="image" />
              </div>
              <div class="slide">
                <img src={karnataka} className="img-sl" alt="image" />
              </div>

              <div class="slide">
                <img src={kmf} className="img-sl" alt="image" />
              </div>
              <div class="slide">
                <img src={milma} className="img-sl" alt="image" />
              </div>
              <div class="slide">
                <img src={amul} className="img-sl" alt="image" />
              </div>
              <div class="slide">
                <img src={karnataka} className="img-sl" alt="image" />
              </div>

              <div class="slide">
                <img src={kmf} className="img-sl" alt="image" />
              </div>
              <div class="slide">
                <img src={milma} className="img-sl" alt="image" />
              </div>
              <div class="slide">
                <img src={amul} className="img-sl" alt="image" />
              </div>
              <div class="slide">
                <img src={karnataka} className="img-sl" alt="image" />
              </div>

              <div class="slide">
                <img src={kmf} className="img-sl" alt="image" />
              </div>
            </div>
          </div>
          <ImageContainer>
            <Images>
              <img src={milma} className="img-sl" alt="image" />
            </Images>
            <Images>
              <img src={amul} className="img-sl" alt="image" />
            </Images>
            <Images>
              <img src={karnataka} className="img-sl" alt="image" />
            </Images>
            <Images>
              <img src={kmf} className="img-sl" alt="image" />
            </Images>
          </ImageContainer>
        </MainContainer>
      </Fade>
    </>
  );
};

export default Partners;

const Title = styled.h1`
  color: #1e3f20;
  font-family: "sans-medium";
  margin-top: 0;
  text-transform: capitalize;
  font-size: 40px;
  text-align: center;
  margin-bottom: 30px;
  line-height: 60px;
  max-width: 60%;
  span {
    white-space: pre;

    background: linear-gradient(
      to right,
      rgb(182, 244, 146),
      rgb(51, 139, 147)
    );
    color: #fff;
    padding: 0 20px;
    margin-left: 10px;
    @media all and (max-width: 1080px) {
      display: block;
      width: 40%;
      margin: 0 auto;
    }
    @media all and (max-width: 980px) {
      width: 45%;
    }
    @media all and (max-width: 768px) {
      width: 60%;
      line-height: 35px;
    }
    @media all and (max-width: 640px) {
      width: 70%;
    }
  }
  margin: 0 auto;
  @media all and (max-width: 1080px) {
    font-size: 35px;
    max-width: 100%;
  }
  @media all and (max-width: 768px) {
    font-size: 30px;
  }
  @media all and (max-width: 640px) {
    font-size: 24px;
  }
  @media all and (max-width: 640px) {
    width: 80%;
    line-height: 45px;
  }
  @media all and (max-width: 480px) {
    width: 90%;
  }
`;
const MainContainer = styled.div`
  margin-top: 80px;
`;
const ImageContainer = styled.div`
  width: 60%;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 30px;

  @media all and (max-width: 480px) {
    display: none;
  }
`;
const Images = styled.div`
  width: 10%;
  margin-right: 30px;
  &:last-child {
    margin-right: 0;
  }
  img {
    width: 100%;
    display: block;
  }
`;
