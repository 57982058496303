import React, { useEffect, useState } from "react";
import styled from "styled-components";
import cow from "../../../assets/images/spotlight/cowbg.png";
import Typewriter from "typewriter-effect";
import { Fade } from "react-reveal";
import Header from "./Header";
import Store from "../../context/Store";
import pdf from "../../../assets/files/brochure.pdf";

const Spotlight = () => {
  const [loopNum, setLoopNum] = useState(0);
  const [isDeleting, setIsDeleting] = useState(false);
  const [text, setText] = useState("");
  const [delta, setDelta] = useState(300 - Math.random() * 200);
  const [index, setIndex] = useState(1);
  const changeModalState = Store((state) => state.changeModalState);

  const toRotate = [
    "Manufactures of Premium Quality Rubber Floorings",
    "A firm Foothold In The Rubber Flooring Industry! ",
  ];
  const period = 2000;

  useEffect(() => {
    let ticker = setInterval(() => {
      tick();
    }, delta);

    return () => {
      clearInterval(ticker);
    };
  }, [text]);

  const tick = () => {
    let i = loopNum % toRotate.length;
    let fullText = toRotate[i];
    let updatedText = isDeleting
      ? fullText.substring(0, text.length - 1)
      : fullText.substring(0, text.length + 1);

    setText(updatedText);

    if (isDeleting) {
      setDelta((prevDelta) => prevDelta / 2);
    }

    if (!isDeleting && updatedText === fullText) {
      setIsDeleting(true);
      setIndex((prevIndex) => prevIndex - 1);
      setDelta(period);
    } else if (isDeleting && updatedText === "") {
      setIsDeleting(false);
      setLoopNum(loopNum + 1);
      setIndex(1);
      setDelta(100);
    } else {
      setIndex((prevIndex) => prevIndex + 1);
    }
  };
  return (
    <>
      <Header />
      <MainContainer id="home">
        <Fade bottom duration={2000}>
          <Wrapper className="wrapper">
            <Content>
              <TextSection>
                <SubTitle>The Natural Rubber Products</SubTitle>
                <Title data-rotate="[ A firm Foothold In The Rubber Floors]">
                  {text}
                </Title>
                <Description>
                  The endearing TJP COW MAT'S are qualitatively Distinct,
                  Extreme Durable and Studded with stening feature.
                </Description>
                <Cover>
                  <Button onClick={() => changeModalState()}>
                    Enquire Now
                  </Button>
                  <Brochure href={pdf} download="TJP Brochure.pdf">
                    <span>Brouchure</span>
                    <i class="ri-download-line"></i>
                  </Brochure>
                </Cover>
              </TextSection>
              <ImageSection>
                <ImageContainer>
                  <img src={cow} alt="cow" />
                </ImageContainer>
              </ImageSection>
            </Content>
          </Wrapper>
        </Fade>
        <Whatsapp
          // className="vert-move"
          href="https://wa.me/+919562511222"
        >
          <i class="ri-whatsapp-fill"></i>
        </Whatsapp>
        <Phone href="tel:+919562511222">
          <i class="ri-phone-fill"></i>
        </Phone>
      </MainContainer>
    </>
  );
};

export default Spotlight;

const MainContainer = styled.div`
  height: calc(100vh - 100px);
  position: relative;
  background: radial-gradient(
    circle at 18.7% 37.8%,
    rgb(250, 250, 250) 0%,
    rgb(225, 234, 238) 90%
  );
  display: flex;
  justify-content: center;
  align-items: center;
  @media all and (max-width: 480px) {
    height: unset;
  }
`;
const Whatsapp = styled.a`
  color: #25d366;
  font-size: 35px;
  background: #fff;
  z-index: 10000;
  padding: 10px;
  text-decoration: none;
  position: fixed;
  cursor: pointer;
  border-radius: 50%;
  bottom: 50px;
  right: 50px;
  @media all and (max-width: 480px) {
    right: 30px;
    font-size: 35px;
  }
`;
const Phone = styled.a`
  color: #002147;
  text-decoration: none;
  left: 30px;
  bottom: 50px;
  position: fixed;
  z-index: 10000;
  font-size: 35px;
  background: #fff;
  border-radius: 50%;
  padding: 10px;
  /* display: none; */
  @media all and (max-width: 480px) {
    font-size: 35px;
  }
`;
const Wrapper = styled.div``;
const Brochure = styled.a`
  background: linear-gradient(to right, rgb(182, 244, 146), rgb(51, 139, 147));
  height: 40px;
  width: 150px;
  color: #fff;
  text-decoration: none;
  border-radius: 5px;
  cursor: pointer;
  justify-content: center;
  display: flex;
  align-items: center;
  display: none;
  @media all and (max-width: 640px) {
    justify-content: center;
    display: flex;
    align-items: center;
  }
`;
const Button = styled.div`
  background: linear-gradient(to right, rgb(182, 244, 146), rgb(51, 139, 147));
  height: 40px;
  width: 150px;
  color: #fff;
  border-radius: 5px;
  cursor: pointer;
  justify-content: center;
  margin-right: 30px;
  display: flex;
  align-items: center;
  &:hover {
    transform: scale(1.05);
    transition: 0.4s ease;
  }
`;
const Cover = styled.div`
  display: flex;
  justify-content: flex-start;
`;
const Content = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  @media all and (max-width: 768px) {
    flex-direction: column-reverse;
  }
`;
const TextSection = styled.div`
  width: 50%;
  @media all and (max-width: 768px) {
    width: 100%;
    /* height: 400px; */
  }
  @media all and (max-width: 480px) {
    height: 450px;
  }
`;
const SubTitle = styled.h4`
  color: #1e3f20;
  font-family: "sans-light";
  font-size: 24px;
`;
const Title = styled.h1`
  color: #1e3f20;
  font-family: "sans-medium";
  margin-top: 0;
  max-width: 600px;
  text-transform: capitalize;
  font-size: 40px;
  @media all and (max-width: 480px) {
    font-size: 30px;
  }
`;
const Description = styled.p`
  color: #1e3f20;
  font-family: "sans-light";
  text-transform: capitalize;
  font-size: 16px;
`;
const ImageSection = styled.div`
  width: 50%;
  @media all and (max-width: 768px) {
    width: 100%;
  }
  @media all and (max-width: 768px) {
    margin-top: 30px;
  }
`;
const ImageContainer = styled.div`
  img {
    display: block;
    width: 100%;
  }
`;
