import React, { useEffect, useState } from "react";
import styled from "styled-components";
import $ from "jquery";
import Store from "../context/Store";
import Contact from "../screens/includes/Contact";
import emailjs from "@emailjs/browser";
import "sweetalert2/dist/sweetalert2.css";
import Swal from "sweetalert2";

function EnquiryModal() {
  const [name, setName] = useState();
  const [email, setEmail] = useState();
  const [number, setNumber] = useState();

  const changeModalState = Store((state) => state.changeModalState);
  const isEnquiryModals = Store((state) => state.isEnquiryModal);
  console.log(isEnquiryModals, "zustand");
  useEffect(() => {
    if (isEnquiryModals) {
      $("html").addClass("modal-enabled");
    } else {
      $("html").removeClass("modal-enabled");
    }
  }, [isEnquiryModals]);

  // success alert
  const showSuccessAlert = () => {
    Swal.fire({
      title: "Success!",
      text: "Will contact you soon.",
      icon: "success",
      confirmButtonColor: "#87986a",
      confirmButtonText: "OK",
    });
  };

  // send email
  const handleEmail = (e) => {
    if (name && number) {
      e.preventDefault();
      const data = {
        from_name: name,
        to_name: email,
        message: number,
      };
      emailjs.send(
        "service_gjyn34o",
        "template_5yn63fa", // india
        // "template_i6nwxgo", // USA
        data,
        "mGK-1GWUN92CAlWdH"
      );
      changeModalState();
      setEmail("");
      setName("");
      setNumber("");
      showSuccessAlert();
    } else {
      e.preventDefault();
    }
  };

  return (
    <MainContainer>
      {isEnquiryModals ? (
        <Overlay onClick={() => changeModalState()}></Overlay>
      ) : (
        ""
      )}
      <BackContainer style={{ transform: isEnquiryModals && "scale(1,1)" }}>
        <Modal type="textarea">
          <Container>
            <a href=""></a>
            <Close
              onClick={() => {
                changeModalState();
                setName("");
                setEmail("");
              }}
            >
              <i class="ri-close-circle-fill"></i>
            </Close>
            <Form>
              <Input
                type="text"
                value={name}
                placeholder="Name"
                onChange={(e) => setName(e.target.value)}
              />
              {/* -----------this field is not required in india----------- */}
              {/* <Input
                type="email"
                value={email}
                placeholder="Email"
                onChange={(e) => setEmail(e.target.value)}
              /> */}
              <Input
                value={number}
                type="number"
                placeholder="Mobile Number"
                onChange={(e) => setNumber(e.target.value)}
              />
              {/* <TextArea></TextArea> */}
              <Submit onClick={(e) => handleEmail(e)}>Submit</Submit>
            </Form>
          </Container>
        </Modal>
      </BackContainer>
    </MainContainer>
  );
}
export default EnquiryModal;

const BackContainer = styled.div`
  position: fixed;
  transition: 0.3s;
  transform: scale(0, 0);
  width: 100%;
  margin: 0 auto;
  right: 0;
  height: 100vh;
  z-index: 1000;
  left: 0;
  top: 0px;
`;
const Overlay = styled.div`
  background: rgba(0, 0, 0, 0.2);
  backdrop-filter: blur(2px);
  position: fixed;
  z-index: 10;
  left: 0;
  top: 0px;
  width: 100%;
  min-height: 100vh;
  max-height: 100vh;
  filter: blur(1px);
`;
const Modal = styled.div`
  width: 90%;
  max-width: 350px;
  margin: 0 auto;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  position: absolute;
  transition: 0.5s;
  z-index: 101;
  border-radius: 5px;
  /* background: #fff; */
  @media all and (max-width: 1280px) {
    width: 520px;
  }
  @media all and (max-width: 1080px) {
    width: 500px;
  }
  @media all and (max-width: 980px) {
    width: 650px;
  }
  @media all and (max-width: 768px) {
    width: 480px;
  }
  @media all and (max-width: 640px) {
    width: 400px;
  }
  @media all and (max-width: 480px) {
    width: 340px;
  }
  @media all and (max-width: 360px) {
    width: 300px;
  }
`;
const MainContainer = styled.div``;

const Container = styled.div`
  position: relative;
`;
const Form = styled.form`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background: #fff;
  padding: 30px;
  border-radius: 8px;
`;
const Input = styled.input`
  width: 100%;
  border-style: solid;
  border-width: 1px 1px 1px 1px;
  border-color: #e9f5db;
  border-radius: 5px;
  margin-bottom: 20px;
  color: #777777;
  box-sizing: border-box;
  &:focus {
    border-color: rgb(135, 152, 106);
  }
  height: 50px;
  padding: 0 20px;
  outline: none;
`;
const Submit = styled.div`
  box-sizing: border-box;
  background: linear-gradient(to right, rgb(182, 244, 146), rgb(51, 139, 147));
  height: 40px;
  color: #fff;
  display: flex;
  justify-content: center;
  width: 100%;
  padding: 0 20px;
  border-radius: 5px;
  align-items: center;
  margin-top: 20px;
  cursor: pointer;
`;
const Close = styled.div`
  position: absolute;
  right: 10px;
  cursor: pointer;
  color: rgb(51, 139, 147);
  top: 10px;
  z-index: 1500;
  font-size: 24px;
`;
