import React, { useState } from "react";
import styled from "styled-components";
import product1 from "../../../assets/images/products/90X90 GYM MAT BACK LOOK.png";
import product2 from "../../../assets/images/products/90X90 GYM MAT.png";
import product3 from "../../../assets/images/products/BUBBLWTOP 2 SIDE LOCK 17MM.png";
import product4 from "../../../assets/images/products/DIAMOND LOCK 17MM LOCK.png";
import product5 from "../../../assets/images/products/DIAMOND TOP HOLLOW BOTTOM 23MM.png";
import product6 from "../../../assets/images/products/DIAMOND TOP STRAIGHT.png";
import product7 from "../../../assets/images/products/GYM MAT,.png";
import product8 from "../../../assets/images/products/GYM MAT.,..png";
import product9 from "../../../assets/images/products/GYM MAT...png";
import product10 from "../../../assets/images/products/GYM MAT.png";
import product11 from "../../../assets/images/products/HOLLOW MAT ROLL.png";
import product12 from "../../../assets/images/products/HOLLOW MAT.png";
import product13 from "../../../assets/images/products/HOLLOW ROLL..png";
import product14 from "../../../assets/images/products/RESTAURANT-RAMP-ROLL.png";
import product15 from "../../../assets/images/products/TJP COW MAT.png";
import product16 from "../../../assets/images/products/TRIANGLE TOP 7X4 17MM.png";
import product17 from "../../../assets/images/products/WhatsApp Image 2023-06-02 at 2.55.46 PM.jpeg";
import { Fade } from "react-reveal";
import Loader from "../../includes/Loader";
import Store from "../../context/Store";

const Product = () => {
  const changeModalState = Store((state) => state.changeModalState);
  const isEnquiryModals = Store((state) => state.isEnquiryModal);
  const productList = [
    // {
    //   id: 1,
    //   image: product1,
    //   title: "90X90 GYM MAT BACK LOOK",
    //   description:
    //     " Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut elittellus, luctus nec ullamcorper mattis pulvinar.",
    // },
    {
      id: 1,
      image: product2,
      title: "Gym Mat (90x90)",
      description:
        " Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut elittellus, luctus nec ullamcorper mattis pulvinar.",
    },
    {
      id: 1,
      image: product7,
      title: "Gym Mat",
      description:
        " Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut elittellus, luctus nec ullamcorper mattis pulvinar.",
    },
    {
      id: 1,
      image: product10,
      title: "Gym Mat",
      description:
        " Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut elittellus, luctus nec ullamcorper mattis pulvinar.",
    },
    {
      id: 2,
      image: product3,
      title: "Bubble top two side lock (17mm)",
      description:
        " Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut elittellus, luctus nec ullamcorper mattis pulvinar.",
    },
    {
      id: 1,
      image: product4,
      title: "Diamond Lock (17mm)",
      description:
        " Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut elittellus, luctus nec ullamcorper mattis pulvinar.",
    },
    {
      id: 1,
      image: product5,
      title: "Diamond Top Hollow bottom (23mm)",
      description:
        " Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut elittellus, luctus nec ullamcorper mattis pulvinar.",
    },
    {
      id: 1,
      image: product6,
      title: "Diamond Top Straight",
      description:
        " Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut elittellus, luctus nec ullamcorper mattis pulvinar.",
    },

    // {
    //   id: 1,
    //   image: product11,
    //   title: "HOLLOW MAT ROLL",
    //   description:
    //     " Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut elittellus, luctus nec ullamcorper mattis pulvinar.",
    // },
    {
      id: 1,
      image: product12,
      title: "Hollow mat",
      description:
        " Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut elittellus, luctus nec ullamcorper mattis pulvinar.",
    },
    {
      id: 1,
      image: product13,
      title: "Hollow Roll",
      description:
        " Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut elittellus, luctus nec ullamcorper mattis pulvinar.",
    },
    {
      id: 1,
      image: product14,
      title: "Restaurant Ramp Roll",
      description:
        " Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut elittellus, luctus nec ullamcorper mattis pulvinar.",
    },
    // {
    //   id: 1,
    //   image: product15,
    //   title: "TJP COW MAT",
    //   description:
    //     " Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut elittellus, luctus nec ullamcorper mattis pulvinar.",
    // },
    {
      id: 1,
      image: product16,
      title: "Traingle Top (7x4)",
      description:
        " Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut elittellus, luctus nec ullamcorper mattis pulvinar.",
    },
    {
      id: 1,
      image: product17,
      title: "Polypropylene Mats",
      description:
        " Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut elittellus, luctus nec ullamcorper mattis pulvinar.",
    },
  ];
  // setInterval(() => {
  //   setTimer(true);
  // }, 2000);

  return (
    <MainContainer id="product">
      <Wrapper className="wrapper">
        <Content>
          <Fade left>
            <Title className="global-title">Our Products</Title>
          </Fade>

          <Products>
            {productList.map((item) => (
              <ProductCard>
                <ImageContainer>
                  <img src={item.image} alt={item.title} />
                </ImageContainer>
                <ProductTitle>{item.title}</ProductTitle>
                <Button onClick={() => changeModalState()}>Enquire Now</Button>
              </ProductCard>
            ))}
          </Products>
        </Content>
      </Wrapper>
    </MainContainer>
  );
};

export default Product;

const MainContainer = styled.div``;
const Wrapper = styled.div``;
const Content = styled.div``;
const Title = styled.h1`
  text-align: center;
`;
const Products = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
`;
const ProductCard = styled.div`
  width: 33.33%;
  text-align: center;
  margin-bottom: 60px;
  @media all and (max-width: 980px) {
    width: 50%;
  }
  @media all and (max-width: 640px) {
    width: 100%;
  }
`;
const ImageContainer = styled.div`
  width: 300px;
  height: 300px;
  object-fit: cover;
  overflow: hidden;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  img {
    display: block;
    width: 100%;
  }
`;
const ProductTitle = styled.h3`
  color: rgb(30, 63, 32);
  font-family: sans-light;
  font-size: 20px;
  text-transform: capitalize;
  text-align: center;
  margin-bottom: 10px;
`;
const PrDetail = styled.p`
  color: rgb(30, 63, 32);
`;
const Button = styled.div`
  text-decoration: none;
  height: 40px;
  background: linear-gradient(to right, rgb(182, 244, 146), rgb(51, 139, 147));
  color: rgb(255, 255, 255);
  display: flex;
  width: 135px;
  -webkit-box-pack: center;
  justify-content: center;
  border-radius: 8px;
  -webkit-box-align: center;
  align-items: center;
  margin: 0px auto;
  cursor: pointer;
  font-size: 14px;
  transition: all 0.4s ease 0s;
  font-size: 16px;
  &:hover {
    transform: scale(1.1);
    transition: 0.4s ease;
  }
`;
