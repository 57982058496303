import React from "react";
import styled from "styled-components";
import ab from "../../../assets/images/about/about.png";
import { Fade } from "react-reveal";
const About = () => {
  return (
    <>
      <Fade bottom duration={2000}>
        <MainContainer id="about">
          <Wrapper className="wrapper">
            <Content>
              <ImageSection>
                <img src={ab} alt="image" />
              </ImageSection>
              <TextSection>
                <SubTitle>About Us</SubTitle>
                <Title>TJP MATS PVT. LTD Prime Emphasis on Quality</Title>
                <Description>
                  To endure the test of time and become the best of all - a
                  simple statement with deeper meaning underscores the brilliant
                  TJP tale. Our success story brings together multiple strands
                  like orientation and commitment, protessionalism and
                  precision, cooperation and accountability and efficacy and
                  transparency that really made us the premium banner in the
                  rubber flooring industry.
                </Description>
              </TextSection>
            </Content>
            <Qualities>
              <Card>
                <IconContainer>
                  <i class="ri-thumb-up-line"></i>
                </IconContainer>
                <CoverDetails>
                  <SmallTitle>Quality Product</SmallTitle>
                  <Detail>
                    Ensuring that you find the perfect product that meets your
                    needs and exceeds your expectations.
                  </Detail>
                </CoverDetails>
              </Card>
              <Card>
                <IconContainer>
                  <i class="ri-check-double-line"></i>
                </IconContainer>
                <CoverDetails>
                  <SmallTitle>Trusted</SmallTitle>
                  <Detail>
                    We believe that trust has the power to transform lives and
                    elevate society.
                  </Detail>
                </CoverDetails>
              </Card>
              <Card>
                <IconContainer>
                  <i class="ri-truck-line"></i>
                </IconContainer>
                <CoverDetails>
                  <SmallTitle>Delivery</SmallTitle>
                  <Detail>
                    Trust us with your shipments, and we'll deliver beyond your
                    expectations.
                  </Detail>
                </CoverDetails>
              </Card>
            </Qualities>
          </Wrapper>
        </MainContainer>
      </Fade>
    </>
  );
};

export default About;
const MainContainer = styled.div`
  margin-bottom: 150px;
  background: linear-gradient(to right, rgb(182, 244, 146), rgb(51, 139, 147));
  padding: 80px 0;
`;
const Wrapper = styled.div``;
const Content = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  @media all and (max-width: 640px) {
    flex-direction: column;
  }
`;
const ImageSection = styled.div`
  width: 30%;
  margin-right: 100px;
  img {
    width: 100%;
    display: block;
  }
  @media all and (max-width: 640px) {
    width: 50%;
    margin: 0 auto;
  }
`;

const TextSection = styled.div`
  width: 50%;
  @media all and (max-width: 640px) {
    width: 100%;
  }
`;
const SubTitle = styled.h4`
  color: rgb(255 255 255);
  font-family: "sans-light";
  font-size: 24px;
  @media all and (max-width: 640px) {
    text-align: center;
  }
`;
const Title = styled.h1`
  color: rgb(255 255 255);
  font-family: "sans-medium";
  margin-top: 0;
  text-transform: capitalize;
  font-size: 40px;
  @media all and (max-width: 640px) {
    text-align: center;
  }
  @media all and (max-width: 480px) {
    font-size: 30px;
  }
`;
const Description = styled.p`
  color: rgb(255 255 255);
  font-family: "sans-light";
  font-size: 16px;
  @media all and (max-width: 480px) {
    text-align: justify;
    font-size: 18px;
    color: rgb(54 40 40);
  }
`;
const Qualities = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 50px 0;
  @media all and (max-width: 768px) {
    flex-wrap: wrap;
  }
`;
const Card = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40%;

  background: rgb(0 0 0 / 12%);
  height: 100px;
  padding: 20px;
  border-radius: 5px;
  margin-right: 20px;

  @media all and (max-width: 768px) {
    width: 85%;
    margin-bottom: 10px;
  }
  @media all and (max-width: 480px) {
    width: 100%;
  }
`;
const IconContainer = styled.div`
  background: rgb(38 48 22);
  color: rgb(255 255 255);
  font-size: 30px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  /* width: 50px;
  height: 50px; */
  padding: 10px;
`;
const CoverDetails = styled.div`
  margin-left: 10px;
`;
const SmallTitle = styled.h2`
  color: rgb(255 255 255);
  font-family: "sans-regular";
  font-size: 20px;
  margin-bottom: 0px;
`;
const Detail = styled.p`
  color: rgb(255 255 255);
  font-size: 14px;
  /* max-width: 80%; */
  line-height: 18px;
`;
