import React from "react";
import styled from "styled-components";
import logo from "../../../assets/images/footer/tjplogo.png";
import tg1 from "../../../assets/images/footer/isocertificate.png";
import tg2 from "../../../assets/images/footer/sedexmember.png";
import { Fade } from "react-reveal";
import accoLogo from "../../../assets/images/accolades_new_logo.png";

const Footer = () => {
  return (
    <>
      <MainContainer>
        <Fade bottom duration={2000}>
          <Wrapper className="wrapper">
            <Content>
              <LogoSection>
                <img src={logo} alt="image" />
              </LogoSection>
              {/* <AddressSection>
              <Cover>
                <Title>Contact</Title>
                <Coverdetails>
                  <Details>
                    <Icon>
                      <i class="ri-phone-fill"></i>
                    </Icon>
                    <a href="tel:088-655-755">+91 9562511222</a>
                  </Details>
                </Coverdetails>
                <Details>
                  <Icon>
                    <i class="ri-mail-fill"></i>
                  </Icon>
                  <a href="mailto:sales@tjpindia.com">sales@tjpindia.com</a>
                </Details>
              </Cover>
              <Cover>
                <Title>Address</Title>
                <Details>
                  <span>
                    Industrial Development Plot,
                    <br /> PO, Poovanthuruthu, <br />
                    Kottayam, Kerala 686012
                  </span>
                </Details>
              </Cover>
            </AddressSection> */}
              <RightSection>
                <Certify>
                  <CertifyCover>
                    <ImgContainer>
                      <img src={tg1} alt="image" />
                    </ImgContainer>
                    <ImgContainer>
                      <img src={tg2} alt="image" />
                    </ImgContainer>
                  </CertifyCover>
                  <DetailsPara>
                    An ISO Certified Company, With Two International
                    Certitications ISO And SEDEX, TJP Has Long Found Its Ingress
                    Toreign Markets Of Middle East. Europe And The Us
                  </DetailsPara>
                </Certify>
              </RightSection>
            </Content>
          </Wrapper>
        </Fade>
      </MainContainer>
      <Banner>
        <span>Copyright © 2024 TJP Mats Pvt.Ltd</span>
        <Logo>
          <a href="https://accoladesintegrated.com/">
            <img src={accoLogo} alt="" />
          </a>
        </Logo>
      </Banner>
    </>
  );
};

export default Footer;

const MainContainer = styled.div`
  background: #fff;
  padding: 50px 0;
  font-family: "sans-regular";
`;
const DetailsPara = styled.p`
  color: #1e3f20;
  font-size: 15px;
`;
const Logo = styled.div`
  width: 120px;
  margin-left: 10px;
  opacity: 0.5;
  /* filter: grayscale(1); */
  a {
    filter: brightness(0.1);
    width: px;
    display: block;
    width: 85px;
  }
  img {
    width: 100%;
    display: block;
  }
`;
const Banner = styled.div`
  background: #f0f4f9;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  gap: 30px;
  span {
    color: #565872;
    font-size: 14px;
  }
`;
const RightSection = styled.div`
  width: 30%;
  @media all and (max-width: 980px) {
    width: 50%;
    margin-top: 50px;
  }
  @media all and (max-width: 480px) {
    width: 100%;
  }
`;
const Content = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  @media all and (max-width: 1080px) {
    flex-wrap: wrap;
  }
  @media all and (max-width: 1080px) {
    justify-content: space-around;
  }
`;
const LogoSection = styled.div`
  width: 30%;
  img {
    display: block;
    width: 100%;
  }
  @media all and (max-width: 768px) {
    width: 60%;
  }
`;
const AddressSection = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50%;
  @media all and (max-width: 1080px) {
    width: 60%;
    align-items: baseline;
  }
  @media all and (max-width: 1080px) {
    width: 100%;
  }
  @media all and (max-width: 480px) {
    flex-direction: column;
  }
`;
const Cover = styled.div`
  width: 42%;
  @media all and (max-width: 480px) {
    width: 100%;
  }
`;
const CertifyCover = styled.div`
  display: flex;
  justify-content: start;
  align-items: center;
`;
const Coverdetails = styled.div``;
const Icon = styled.div`
  margin-right: 5px;
`;
const Details = styled.div`
  margin-bottom: 10px;
  display: flex;
  a {
    text-decoration: none;
    color: #1e3f20;
    font-size: 16px;
  }
  span {
    color: #1e3f20;
    font-size: 15px;
    display: block;
  }
`;
const Wrapper = styled.div``;
const ImgContainer = styled.div`
  width: 35%;
  img {
    display: block;
    width: 100%;
  }
`;
const Certify = styled.div``;
