import React, { useState } from "react";
import { Fade } from "react-reveal";
import styled from "styled-components";
import emailjs from "@emailjs/browser";
import "sweetalert2/dist/sweetalert2.css";
import Swal from "sweetalert2";
import Store from "../../context/Store";

const Contact = () => {
  const [name, setName] = useState();
  const [email, setEmail] = useState();
  const [number, setNumber] = useState();

  // success alert
  const showSuccessAlert = () => {
    Swal.fire({
      title: "Success!",
      text: "Will contact you soon.",
      icon: "success",
      confirmButtonColor: "#87986a",
      confirmButtonText: "OK",
    });
  };
  // send email
  const handleEmail = (e) => {
    if (name && email) {
      e.preventDefault();
      const data = {
        from_name: name,
        to_name: email,
        message: number,
      };
      emailjs.send(
        "service_gjyn34o",
        "template_5yn63fa", //india
        // "template_i6nwxgo", // USA
        data,
        "mGK-1GWUN92CAlWdH"
      );
      setEmail("");
      setName("");
      setNumber("");
      // changeModalState();
      showSuccessAlert();
    } else {
      e.preventDefault();
    }
  };
  return (
    <MainContainer id="contact">
      <Fade bottom duration={2000}>
        <Wrapper className="wrapper">
          <Content>
            <FormSection>
              <SubTitle>You Need Help?</SubTitle>
              <Title>Get In Touch</Title>
              <Description>
                Your satisfaction is our top priority, and we believe that open
                communication is the key to building strong relationships with
                our customers. We are always ready to listen to your feedback,
                suggestions, and ideas for improvement. Your input helps us
                enhance our products and services, ensuring that we continue to
                meet and exceed your expectations.
              </Description>
              <Form>
                <Input
                  type="text"
                  value={name}
                  placeholder="Enter your name"
                  onChange={(e) => setName(e.target.value)}
                />
                {/* -----------this field is not required in india----------- */}

                {/* <Input
                  type="email"
                  value={email}
                  placeholder="Enter your email"
                  onChange={(e) => setEmail(e.target.value)}
                /> */}
                <Input
                  type="number"
                  value={number}
                  placeholder="Enter your number"
                  onChange={(e) => setNumber(e.target.value)}
                />
                {/* <TextArea></TextArea> */}
                <Submit onClick={(e) => handleEmail(e)}>Submit</Submit>
              </Form>
            </FormSection>
            <AddressSection>
              <Cover>
                <Icon>
                  <i class="ri-phone-fill"></i>
                </Icon>
                <Coverdetails>
                  {/* ----usa---- */}
                  {/* <Details>
                    <a href="tel:+917593801800">+91 7593801800</a>
                  </Details>
                  <Details>
                    <a href="tel:+918281168636">+91 8281168636</a>
                  </Details> */}
                  {/* -----india---- */}
                  <Details>
                    <a href="tel:+919562511222">+91 9562511222</a>
                  </Details>
                </Coverdetails>
              </Cover>
              <Cover>
                <Icon>
                  <i class="ri-mail-fill"></i>
                </Icon>
                <Details>
                  <a href="mailto:sales@tjpindia.com">sales@tjpindia.com</a>
                </Details>
              </Cover>
              <Cover>
                <Icon>
                  <i class="ri-map-pin-fill"></i>
                </Icon>
                <Details>
                  <span>
                    GP Rubber Mats Inc 501 <br />
                    Gansevoort Blvd Staten Island,
                    <br /> NY 10314-5256
                  </span>
                </Details>
              </Cover>
            </AddressSection>
          </Content>
        </Wrapper>
      </Fade>
    </MainContainer>
  );
};

export default Contact;

const MainContainer = styled.div`
  background: #f8fff0;
  padding: 80px 0;
`;
const Wrapper = styled.div``;
const Coverdetails = styled.div`
  display: flex;
  justify-content: center;
  align-items: inherit;
  flex-direction: column;
`;

const Content = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: end;
  @media all and (max-width: 980px) {
    flex-direction: column;
    align-items: center;
  }
`;
const FormSection = styled.div`
  width: 50%;
  @media all and (max-width: 640px) {
    width: 100%;
  }
`;
const SubTitle = styled.h4`
  color: #1e3f20;
  font-family: "sans-light";
  font-size: 24px;
`;
const Title = styled.h1`
  color: #1e3f20;
  font-family: "sans-medium";
  margin-top: 0;
  text-transform: capitalize;
  font-size: 40px;
`;
const Description = styled.p`
  color: #1e3f20;
  font-family: "sans-light";
  font-size: 16px;
  text-align: justify;
`;
const Form = styled.form`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;
const TextArea = styled.textarea`
  width: 100%;
  color: #87986a;
  border-style: solid;
  border-width: 1px 1px 1px 1px;
  border-color: #e9f5db;
  border-radius: 5px;
  &:focus {
    border-color: rgb(135, 152, 106);
  }
  padding: 20px;
  height: 150px;
  outline: none;
`;
const Submit = styled.div`
  background: linear-gradient(to right, rgb(182, 244, 146), rgb(51, 139, 147));
  height: 40px;
  color: #fff;
  display: flex;
  justify-content: center;
  width: 100%;
  padding: 0 20px;
  border-radius: 5px;
  align-items: center;
  margin-top: 20px;
  cursor: pointer;
`;
const Input = styled.input`
  width: 100%;
  border-style: solid;
  border-width: 1px 1px 1px 1px;
  border-color: #e9f5db;
  border-radius: 5px;
  margin-bottom: 20px;
  font-size: 16px;
  color: #141414;
  &::placeholder {
    color: #87986a;
    font-size: 14px;
  }
  &:focus {
    border-color: rgb(135, 152, 106);
  }
  height: 50px;
  padding: 0 20px;
  outline: none;
`;
const AddressSection = styled.div`
  width: 30%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  @media all and (max-width: 980px) {
    width: 100%;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-top: 60px;
  }
`;
const Cover = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
  align-items: inherit;
`;
const Details = styled.div`
  margin-bottom: 10px;
  a {
    text-decoration: none;
    color: #1e3f20;
    font-size: 16px;
    margin-left: 10px;
    @media all and (max-width: 980px) {
      font-size: 18px;
    }
  }
  span {
    color: #1e3f20;
    font-size: 16px;
    line-height: 22px;
    margin-left: 10px;
    display: block;
    /* @media all and (max-width: 980px) {
      font-size: 18px;
    } */
  }
  @media all and (max-width: 980px) {
    font-size: 18px;
  }
`;
const Icon = styled.div`
  height: 50px;
  width: 50px;
  background: linear-gradient(to right, rgb(182, 244, 146), rgb(51, 139, 147));
  border-radius: 50%;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
`;
