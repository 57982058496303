import React from "react";
import Spotlight from "./includes/Spotlight";
import About from "./includes/About";
import "remixicon/fonts/remixicon.css";
import Partners from "./includes/Partners";
import Product from "./includes/Product";
import Contact from "./includes/Contact";
import Footer from "./includes/Footer";
import Header from "./includes/Header";
import EnquiryModal from "../includes/EnquiryModal";
import "remixicon/fonts/remixicon.css";

// import styled from "styled-components";
const LandingPage = () => {
  return (
    <>
      {/* <Header /> */}
      <Spotlight />
      <About />
      <Product />
      <Partners />
      <Contact />
      <Footer />
      <EnquiryModal />
    </>
  );
};

export default LandingPage;
