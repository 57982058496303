import React, { useState } from "react";
import styled from "styled-components";
import logo from "../../../assets/images/footer/tjplogo.png";
import Fade from "react-reveal/Fade";
import pdf from "../../../assets/files/brochure.pdf";

const Header = () => {
  return (
    <MainContainer>
      <Fade bottom>
        <Wrapper className="wrapper">
          <Content>
            <LogoSection>
              <img src={logo} alt="image" />
            </LogoSection>
            <Coverdetails>
              <Details>
                <Icon>
                  <i class="ri-phone-fill"></i>
                </Icon>
                <a href="tel:+91 9562511222">+91 9562511222</a>
              </Details>
              <Details>
                <Icon>
                  <i class="ri-mail-fill"></i>
                </Icon>
                <a href="mailto:sales@tjpindia.com">sales@tjpindia.com</a>
              </Details>
              <Brochure href={pdf} download>
                <span>Brochure</span>
                <i class="ri-download-line"></i>
              </Brochure>
            </Coverdetails>
          </Content>
        </Wrapper>
      </Fade>
    </MainContainer>
  );
};

export default Header;

const MainContainer = styled.div`
  background: radial-gradient(
    circle at 18.7% 37.8%,
    rgb(250, 250, 250) 0%,
    rgb(225, 234, 238) 90%
  );
`;
const Coverdetails = styled.div`
  display: flex;
  align-items: center;
  @media all and (max-width: 640px) {
    display: none;
  }
`;
const Icon = styled.div`
  margin-right: 5px;
  color: rgb(75 117 77);
`;
const Wrapper = styled.div``;
const Details = styled.div`
  margin-right: 30px;
  display: flex;
  a {
    text-decoration: none;
    color: rgb(75 117 77);
    font-size: 18px;
  }
  span {
    color: rgb(75 117 77);
    font-size: 15px;
    display: block;
  }
  @media all and (max-width: 980px) {
    display: none;
  }
`;
const Brochure = styled.a`
  background: linear-gradient(to right, rgb(182, 244, 146), rgb(51, 139, 147));
  height: 40px;
  width: 150px;
  color: #fff;
  text-decoration: none;
  border-radius: 5px;
  cursor: pointer;
  justify-content: center;
  display: flex;
  align-items: center;
  &:hover {
    transform: scale(1.05);
    transition: 0.4s ease;
  }
  span {
    margin-right: 10px;
  }
`;
const Content = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
const LogoSection = styled.div`
  width: 25%;
  img {
    display: block;
    width: 100%;
  }
  @media all and (max-width: 980px) {
    width: 35%;
  }
  @media all and (max-width: 640px) {
    width: 60%;
  }
  @media all and (max-width: 480px) {
    width: 80%;
  }
`;
const NavItems = styled.div`
  width: 45%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  @media all and (max-width: 768px) {
    width: 60%;
  }
  @media all and (max-width: 640px) {
    display: none;
  }
`;
const Item = styled.a`
  text-decoration: none;
  font-family: "sans-medium";
  cursor: pointer;
  color: #1e3f20;
  &:hover {
    color: #87986a;
  }
  &.active {
    color: #87986a;
  }
`;
